import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { useAuth } from "shared/auth";
import { openLoginModal } from "~/utils/commonFunc";

export const useWaitForLoggedIn = (callback) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const loginParam = searchParams.get("login");
  const history = useHistory();

  const { auth } = useAuth();

  const [holdingData, setHoldingData] = useState(undefined);

  const onSetHoldingData = (data) => {
    setHoldingData(data);
    openLoginModal(history, location);
  }

  useEffect(() => {
    // if user is logged in then "login" param will disapear and token will available
    if (holdingData && !loginParam && !!auth?.token) {
      callback(holdingData);
      setHoldingData(undefined);
    }
  }, [holdingData, loginParam, auth?.token]);

  return [onSetHoldingData];
};