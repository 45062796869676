import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core/';

import { withRouter } from 'react-router-dom';
import { products } from '../lifeforcefood/productMockData';
import { useFetchMealPlans, useFetchNutritionAnalysis, useFetchProduct, useFetchProductsByIds } from 'shared/react-query/hooks';
import Status from '~/components/ui/Status';
import { statusObj } from '~/utils/commonFunc';
import NutritionalData from '../NutritionalDetail/Sections/NutritionalData';
import NutritionalSummary from '../NutritionalDetail/Sections/NutritionalSummary';

const CALORIES_COMPOSITION_MAPPER_LIST = [
  { id: 1, toName: 'carbohydrates', fromName: 'carbs' },
  { id: 2, toName: 'proteins', fromName: 'protein' },
  { id: 3, toName: 'fats', fromName: 'fat' },
  { id: 4, toName: 'sugar', fromName: 'sugars' },
];

const styles = (theme) => ({
  root: {
    color: '#343434',
    // paddingTop: '100px',
    background: 'rgba(24,24,24,0.5)',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '80px',
    },
  },
  foodChart: {
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0px 20px 0px',
    },
  },
  foodChartTable: {
    [theme.breakpoints.down('xs')]: {
      overflowX: 'scroll',
    },
  },
  mainContent: {
    backgroundColor: '#fff',
  },
  imageBox: {
    marginLeft: '25px',
    gap: '16px',
  },
  prdImage: {
    border: '4px solid white',
    width: '120px',
    height: '120px',
    marginTop: '-50px',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: '80px',
      height: '80px',
      marginTop: '-30px',
    },
  },
  viewLink: {
    color: '#9aa200',
    font: 'Lato',
    fontSize: '1rem',
    textDecorationColor: '#9aa200',
  },

});

function NutritionalDetail(props) {
  const { classes, location, history } = props;
  const [itemData, setItemData] = useState(null);
  const myRef = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  const myRef4 = useRef(null);
  const myRef5 = useRef(null);
  const myRef6 = useRef(null);
  const myRef7 = useRef(null);

  const { data: mealPlans } = useFetchMealPlans();
  const searchParams = new URLSearchParams(location.search);
  const mealPlan = mealPlans?.find((mealPlan) => mealPlan.id === parseInt(searchParams.get('mealPlanId')));
  const { data: productsFromMealPlan, isLoading } = useFetchProductsByIds(mealPlan?.recipes);

  const { data: nutritionData } = useFetchNutritionAnalysis(productsFromMealPlan?.map((product) => product.id));


  useEffect(() => {
    if (!!nutritionData) {
      const product = products[0];
      product.nurtritionalAnalysis = {
        ...product.nurtritionalAnalysis,
        micronutrients: nutritionData?.micronutrients,
        caloriecomposition: CALORIES_COMPOSITION_MAPPER_LIST.map(calCom => ({
          id: calCom.id,
          name: calCom.toName,
          data: nutritionData?.caloricsBreakdown?.find((cal) => cal.name === calCom.fromName)?.percentage,
        })),
      };

      setItemData(product);
    }
  }, [nutritionData]);

  return (
    <Status statusObj={isLoading ? statusObj('loading') : statusObj('done')}>

      <Box className={classes.root}>
        {/* <Box pb={4}> */}
        {/* <SetBackgroundComponent headerBackground={headerBackground} className={classes.headerbg} /> */}
        {/* <Container maxWidth="lg"> */}
        {/* <BreadcrumbsComponent text={itemData && itemData.name} routes={routes} /> */}
        {/* </Container> */}
        {/* </Box> */}
        <Box className={classes.mainContent}>
          <Container maxWidth="lg">
            <Box className={classes.imageBox} display="flex" mb={2}>
              {productsFromMealPlan?.map((productData) => (
                <img key={`${productData.id}`} className={classes.prdImage} src={productData?.variants?.nodes?.[0].image?.src} alt="product image" />
              ))}
            </Box>
          </Container>
          {/* <NutritionalData data={productsFromMealPlan?.[0]} nutritionalData={myRef7} /> */}
          <Box mt="30px">
            <div ref={myRef}></div>
            <NutritionalSummary analysisRef={myRef2} data={itemData} micronutrientsRef={myRef3} calorieCompositionRef={myRef4} specialFuncRef={myRef5} deliverySiRef={myRef6} />
          </Box>
        </Box>
      </Box>
    </Status>
  );
}

export default withRouter(withStyles(styles)(NutritionalDetail));
