import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Container, Divider } from '@material-ui/core/';
import ChevronDownIcon from '@material-ui/icons/ChevronRight';
import ProgressChart from '~/components/ui/ProgressChart';
import PieChart from '~/components/ui/PieChart';
import Lengends from './Lengends';

import IconMicroscope from '~/assets/images/page/lifeforcefood/nutrition/icon_microscope.png';

const styles = (theme) => ({
  root: {},
  mainTitle: {
    color: '#9aa300',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  statusBox: {
    backgroundColor: 'rgba(154,162,0, 0.1)',
    borderLeft: 'solid 5px #9aa200 ',
    padding: '10px 10px',
  },
  iconMicroscope: {
    width: '100%',
  },
  itemtitle: {
    color: '#434343',
    fontSize: '18px',
    fontWeight: '750',
    letterSpacing: '1px',
  },
  iconArrow: {
    color: '#8c8c8c',
    cursor: 'pointer',
  },
  itemname: {
    color: '#434343',
    fontSize: '14px',
    fontWeight: '300',
  },
  itemDes: {
    color: '#434343',
    fontSize: '14px',
    marginTop: '20px',
    lineHeight: '24px',
  },
});

const MICRONUTRIENTS_LIST = [
  { id: 1, name: 'vitamins', displayName: "1 vitamins", color: "#9aa200" },
  { id: 2, name: 'macrominerals', displayName: "2 macrominerals", color: "#1f78b4" },
  { id: 3, name: 'microminerals', displayName: "3 microminerals", color: "#7e489c" },
  { id: 4, name: 'amino acids', displayName: "4 amino acids", color: "#b9ad13" },
];

const CALORIES_COMPOSITION_LIST = [
  { id: 1, name: 'carbohydrates', displayName: "1 carbohydrates", color: "#ec5569" },
  { id: 2, name: 'proteins', displayName: "2 proteins", color: "#7e489c" },
  { id: 3, name: 'fats', displayName: "3 fats", color: "#659bbf" },
  { id: 4, name: 'sugar', displayName: "4 sugars", color: "#9dc05e" },
];

function NutritionalSummary(props) {
  const { classes, data } = props;
  if (!data) return <></>;

  const calList = CALORIES_COMPOSITION_LIST.map((calorieItem) => {
    const item = data.nurtritionalAnalysis.caloriecomposition.find(cal => cal.name === calorieItem.name);
    return {
      ...calorieItem,
      data: item.data,
    }
  });

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography align="center" className={classes.mainTitle}>
        {' '}
        Nutritional Summary Status
      </Typography>

      <Box className={classes.statusBox} display="flex" justifyContent="center" mt={2} flexDirection="column">
        <Typography align="right">Industry / Health Creator</Typography>
        <Box mt="5px" display="flex" justifyContent="space-between" width="100%">
          <Typography component="div">
            <strong>Servings per package: </strong>
            {data && data.nurtritionalSum.servePerPkg}
          </Typography>
          <Typography component="div">
            <strong>Serving Size: </strong> {data && data.nurtritionalSum.serveSize}
          </Typography>
          <Typography component="div">
            <strong>Per: </strong>100g
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography component="div">
            <strong>Nutrient density: </strong> {data && data.nurtritionalSum.nutrientDensity}
          </Typography>
          <Typography component="div">
            <strong>Life Force:</strong>
            {data && data.nurtritionalSum.lifeForce}
          </Typography>
          <Typography component="div">
            <strong>H320: </strong> {data && data.nurtritionalSum.h320}
          </Typography>
          <Typography component="div">
            <strong>Raw: </strong>
            {data && data.nurtritionalSum.raw}
          </Typography>
        </Box>
      </Box>
      <div ref={props.analysisRef}></div>
      <Box mt="30px" mb="30px" position="relative" color="#8C8C8C">
        <Typography align="center">
          <strong>NUTRITIONAL ANALYSIS</strong>
        </Typography>
        <Box color="#9aa300" position="absolute" right="0" top="0">
          <Typography>120gm - 5 ounce</Typography>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" pt="20px" pb="20px">
        <Box mr="40px" width="70px">
          <img src={IconMicroscope} alt="microscope" className={classes.iconMicroscope} />
        </Box>
        <Box flex="1">
          <div ref={props.micronutrientsRef}></div>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={classes.itemtitle}>1. Micronutrients</Typography>
            <ChevronDownIcon className={classes.iconArrow} />
          </Box>
          <Box pr="60px" mt="15px">
            <Grid container spacing={1}>
              {MICRONUTRIENTS_LIST.map((microNutrientItem) => {
                const item = data.nurtritionalAnalysis.micronutrients.find(mic => mic.name === microNutrientItem.name);
                return (
                  <Grid key={microNutrientItem.id} item lg={3} md={6} sm={6} xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography className={classes.itemname}>{microNutrientItem.displayName}</Typography>
                      <ProgressChart color={microNutrientItem.color} radius={25} percentage={item.data / 100} />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
            <Typography className={classes.itemDes}>{data && data.nurtritionalAnalysis.microDes}</Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" pt="20px" pb="20px">
        <Box mr="40px" width="70px">
          <img src={IconMicroscope} alt="microscope" className={classes.iconMicroscope} />
        </Box>
        <Box flex="1">
          <div ref={props.calorieCompositionRef}></div>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={classes.itemtitle}>2. Calorie composition</Typography>
            <ChevronDownIcon className={classes.iconArrow} />
          </Box>
          <Box pr="60px" mt="15px">
            <Grid container spacing={1}>
              {calList.map((calorieItem) => {
                return (
                  <Grid key={calorieItem.id} lg={3} md={6} sm={6} xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography className={classes.itemname}>{calorieItem.displayName}</Typography>
                      <ProgressChart color={calorieItem.color} radius={25} percentage={calorieItem.data / 100} />
                    </Box>
                  </Grid>)
              })}
            </Grid>
            <Grid container>
              <Grid item md={8} sm={6} xs={12}>
                <Typography className={classes.itemDes}>{data && data.nurtritionalAnalysis.calDes}</Typography>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Box display="flex" flexDirection="column" alignItems="center" mt="10px">
                  <PieChart data={calList.map(cal => ({ value: cal.data, color: cal.color }))} width={150} height={150} innerRadius={45} outerRadius={75} value={'Composition'} />
                  <Lengends
                    dataList={calList.map(cal => ({
                      color: cal.color,
                      text: cal.displayName + " " + cal.data + "%"
                    }))}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" pt="15px" pb="20px">
        <Box mr="40px" width="70px">
          <img src={IconMicroscope} alt="microscope" className={classes.iconMicroscope} />
        </Box>
        <Box flex="1">
          <div ref={props.specialFuncRef}></div>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={classes.itemtitle}>3. Specialised Functionality</Typography>
            <ChevronDownIcon className={classes.iconArrow} />
          </Box>
          <Box pr="60px" mt="15px">
            <Grid container spacing={2}>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography className={classes.itemname}>1 Glycaemic Index (GI)</Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Lengends
                    dataList={[
                      { text: 'High >70', color: '#ea9591' },
                      { text: 'Medium 56 - 69 ', color: '#ecc59c' },
                      { text: 'Low <= 55', color: '#d7da9a' },
                    ]}
                  />
                  <PieChart i={0} width={50} height={50} innerRadius={15} outerRadius={25} value={48} />
                </Box>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography className={classes.itemname}>2 Arginine / lysine ratio</Typography>
                <Grid container alignItems="center">
                  <Box display="flex" width={1} justifyContent="space-between" alignItems="center">
                    <Typography className={classes.itemname}>Arginine</Typography>
                    <ProgressChart color="#9aa200" radius={25} percentage={0.62} />
                  </Box>
                  <Box display="flex" width={1} justifyContent="space-between" alignItems="center">
                    <Typography className={classes.itemname}>Lysine</Typography>
                    <ProgressChart color="#9ab255" radius={25} percentage={0.35} />
                  </Box>
                </Grid>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography className={classes.itemname}>3 HDL Cholesterol mg/dl</Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Lengends
                    dataList={[
                      { text: 'High Desirable > 60', color: '#9dc05e' },
                      { text: 'Acceptable 40 - 60', color: '#cadb80' },
                      { text: 'Undesirable < 40', color: '#ea9591' },
                    ]}
                  />
                  <PieChart i={1} width={50} height={50} innerRadius={15} outerRadius={25} value={48} />
                </Box>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography className={classes.itemname}>4 PH level</Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Lengends
                    dataList={[
                      { text: 'Acidic 1 ~ 6', color: '#eeaaa7' },
                      { text: 'Neutral 7', color: '#77c6ae' },
                      { text: 'Alkaline 8 ~ 14', color: '#9390b8' },
                    ]}
                  />
                  <PieChart i={2} width={50} height={50} innerRadius={15} outerRadius={25} value={48} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" pt="15px" pb="20px">
        <Box mr="40px" width="70px">
          <img src={IconMicroscope} alt="microscope" className={classes.iconMicroscope} />
        </Box>
        <Box flex="1">
          <div ref={props.deliverySiRef}></div>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={classes.itemtitle}>4. Delivery of specialised ingredients</Typography>
            <ChevronDownIcon className={classes.iconArrow} />
          </Box>
          <Box pr="60px" mt="15px">
            <Grid container>
              <Grid item md={8} sm={6} xs={12}>
                <Typography className={classes.itemDes}>{data && data.nurtritionalAnalysis.deliverySpecial}</Typography>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Box display="flex" flexDirection="column" alignItems="center" mt="10px">
                  <PieChart i={3} width={150} height={150} innerRadius={45} outerRadius={75} value={'Composition'} />
                  <Lengends
                    dataList={[
                      { color: '#9bbad3', text: 'Dapibus 42%' },
                      { color: '#c2dc85', text: 'Amet non metus 28%' },
                      { color: '#ecc59c', text: 'Ame morbi  17%' },
                      { color: '#77c6ae', text: 'Molestie odio ultrices 8%' },
                    ]}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Container>
  );
}

export default withStyles(styles)(NutritionalSummary);
