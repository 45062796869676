import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_STRAPI_API_URL}/`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`
  }
});

const populateField = (strapiFieldName, returnFieldName) => {
  return `&populate[${strapiFieldName}]=${returnFieldName}`;
}

const uploadFile = async (formData, token) => {
  try {
    const response = await apiClient.post('api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-shopify-access-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const uploadAvatarFile = async (file, namePrefix, id, token) => {
  try {
    const formData = new FormData();
    formData.append('files', file, `${namePrefix}_${file.name}`);
    formData.append('ref', 'api::visionaire.visionaire');
    formData.append('refId', id);
    formData.append('field', 'displayPhotosAndVideos');
    formData.append('path', 'avatars');

    const response = await uploadFile(formData, token);
    return response.data;
  } catch (error) {
    console.error('Error uploading avatar file:', error);
    throw error;
  }
};

export const fetchArticle = async (slug) => {
  try {
    let queryBuilder = `api/articles?filters[slug][$eq]=${slug}`;
    queryBuilder += `&populate[carousel][fields][0]=url`;
    queryBuilder += `&populate[visionaire][fields][0]=slug`;
    queryBuilder += `&populate[hcn_article_category][fields][0]=id`;
    queryBuilder += `&populate[hcn_article_category][fields][1]=title`;
    queryBuilder += `&populate[hcn_article_category][fields][2]=color`;
    queryBuilder += `&populate[hcn_article_category][fields][3]=parent`;
    queryBuilder += `&populate[hcn_article_category][populate][Parent][fields][0]=id`;
    queryBuilder += `&populate[hcn_article_category][populate][Parent][fields][1]=color`;
    const response = await apiClient.get(queryBuilder);
    return response.data;
  } catch (error) {
    console.error('Error fetching article:', error);
    throw error;
  }
};


export const fetchAdvancementExerciseCategory = async (slug, token) => {
  try {
    let queryBuilder = `api/advancement-exercise-categories/${slug}?`;
    queryBuilder += `populate[videoSegment][fields][0]=title&populate[videoSegment][fields][1]=description&populate[videoSegment][populate][video][fields][0]=url`;
    queryBuilder += `&populate[videoSequence][fields][0]=title&populate[videoSequence][fields][1]=description&populate[videoSequence][populate][video][fields][0]=url`;
    const response = await apiClient.get(queryBuilder, {
      headers: {
        "x-shopify-access-token": token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching exercise:', error);
    throw error;
  }
};

export const fetchVideo = async (segment) => {
  try {
    const response = await apiClient.get(`${segment}`, {
      responseType: 'blob' // Ensure response type is 'blob'
    });
    const videoBlob = response.data;
    const buf = await videoBlob.arrayBuffer();
    const videoUrl = URL.createObjectURL(new Blob([videoBlob], { type: "video/mp4" }));
    return videoUrl;
  } catch (error) {
    console.error('Error fetching exercise video:', error);
    throw error;
  }
};

export const fetchVisionaireProfile = async (slug, token) => {
  try {
    const response = await apiClient.get(`api/visionaires/${slug}`, {
      headers: {
        "x-shopify-access-token": token ?? ""
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};


export const fetchProfile = async (token) => {
  try {
    const response = await apiClient.get(`api/profile`, {
      headers: {
        "x-shopify-access-token": token ?? ""
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};

export const updateProfile = async (profile, token) => {
  try {
    const response = await apiClient.put(`api/profile`, profile, {
      headers: {
        "x-shopify-access-token": token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

/**
 * @typedef {Object} ArticleAttribute
 * @property {string} Body
 * @property {Object} carousel
 * @property {Object} hcn_article_category
 * @property {string} title
 * @property {Object} slug
 * @property {string} summary
 */

/**
 * @typedef {Object} Article
 * @property {number} id
 * @property {ArticleAttribute} attributes
 */

/**
 * Fetches all articles by category ids
 * @param {string} userSlug
 * @param {number[]} articleCategoryIds
 * @param {number} pageNumber
 * @param {number} pageSize
 * @returns {Promise<{data: Article[]}>}
 * @throws {Error}
 */
const fetchArticlesByCategoryIdsInternal = async (userSlug, articleCategoryIds, pageNumber, pageSize = 9) => {
  try {
    let queryFilterBuilder = "";
    articleCategoryIds.forEach((articleCategoryId, index) => {
      if (index > 0) {
        queryFilterBuilder += "&";
      }
      queryFilterBuilder += `filters[$or][0][hcn_article_category][id][$eq]=${articleCategoryId}&filters[$or][1][hcn_article_category][Children][id][$eq]=${articleCategoryId}&filters[$or][2][hcn_article_category][Parent][id][$eq]=${articleCategoryId}`;
    });
    if (userSlug) {
      queryFilterBuilder += `&filters[$and][0][visionaire][slug][$eq]=${userSlug}`;
    }

    queryFilterBuilder += `&populate[visionaire][fields][0]=slug`;
    queryFilterBuilder += `&populate[carousel][fields][0]=url&populate[hcn_article_category][fields][0]=id&populate[hcn_article_category][fields][1]=title&populate[hcn_article_category][fields][2]=parent&populate[hcn_article_category][fields][3]=children&populate[hcn_article_category][populate][Parent][fields][0]=id&populate[hcn_article_category][populate][Parent][fields][1]=title&populate[hcn_article_category][populate][Children][fields][0]=id&populate[hcn_article_category][populate][Children][fields][1]=title`;

    const response = await apiClient.get(`api/articles?${queryFilterBuilder}&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};

const mapArticleEntity = (e) => ({
  id: e.id,
  img: e.attributes.carousel.data[0].attributes.url,
  title: e.attributes.title,
  slug: e.attributes.slug,
  summary: e.attributes.summary,
  description: e.attributes.Body,
  category: {
    id: e.attributes.hcn_article_category.data.id,
  },
  visionaire: e.attributes.visionaire && {
    slug: e.attributes.visionaire?.data.attributes.slug,
  }
});

/**
 * @typedef {Object} ArticleEntity
 * @property {number} id
 * @property {string} img
 * @property {string} title
 * @property {string} slug
 * @property {string} summary
 * @property {string} description
 * @property {Object} category
 * @property {number} category.id
 */

/**
 * Fetches all articles by category ids
 * @param {number[]} articleCategoryIds
 * @param {number} pageNumber
 * @param {number} pageSize
 * @returns {Promise<ArticleEntity[]>}
 */
export const fetchArticlesByCategoryIds = async (articleCategoryIds, pageNumber, pageSize = 9) => {
  const result = await fetchArticlesByCategoryIdsInternal(null, articleCategoryIds, pageNumber, pageSize);
  return result?.data?.map((e) => mapArticleEntity(e));
};

export const fetchUserArticlesByCategoryIds = async (userSlug, articleCategoryIds, pageNumber, pageSize = 9, token) => {
  const result = await fetchArticlesByCategoryIdsInternal(userSlug, articleCategoryIds, pageNumber, pageSize);
  return result?.data?.map((e) => mapArticleEntity(e));
};

/**
 * @typedef {Object} ParentAttribute
 * @property {string} title
 */

/**
 * @typedef {Object} ParentData
 * @property {number} id
 * @property {ParentAttribute} attributes
 */
/**
 * @typedef {Object} Parent
 * @property {ParentData} data
 */

/**
 * @typedef {Object} ArticleCategoryAttribute
 * @property {string} color
 * @property {string} title
 * @property {Parent} Parent
 */

/**
 * @typedef {Object} ArticleCategory
 * @property {number} id
 * @property {ArticleCategoryAttribute} attributes
 */

/**
 * Fetches all article categories
 * @returns {Promise<{data: ArticleCategory[]}>}
 */
export const fetchHCNArticleCategoriesInternal = async () => {
  try {
    let queryBuilder = `api/hcn-article-categories?`;
    queryBuilder += `fields[0]=id&fields[1]=title&fields[3]=color&fields[4]=subText`;
    queryBuilder += `&populate[Parent][fields][0]=id&populate[Parent][fields][1]=title&populate[Children][fields][0]=id&populate[Children][fields][1]=title&pagination[pageSize]=100`;
    queryBuilder += `&sort[0]=id`;
    const response = await apiClient.get(queryBuilder);
    return response.data;
  } catch (error) {
    console.error('Error fetching HCNArticleCategories:', error);
    throw error;
  }
};

/**
 * @typedef {Object} HCNCategory
 * @property {number} id
 * @property {string} color
 * @property {string} title
 * @property {string} subText
 * @property {number} parentId
 * @property {string} parentTitle
 * @property {HCNCategory[]} children
 */

/**
 * Fetches all article categories
 * @returns {Promise<HCNCategory[]>}
 */
export const fetchHCNArticleCategoriesWarper = async () => {
  const result = await fetchHCNArticleCategoriesInternal();
  return result?.data?.map((e) => ({
    id: e.id,
    title: e.attributes.title,
    color: e.attributes.color ?
      e.attributes.color :
      result.data.find((x) => x.id === e.attributes.Parent?.data?.id)?.attributes?.color,
    subText: e.attributes.subText,
    parentId: e.attributes.Parent?.data?.id,
    parentTitle: e.attributes.Parent?.data?.attributes?.title,
    children: e.attributes.Children?.data?.map((x) => ({
      id: x.id,
      title: x.attributes.title,
      color: x.attributes.color,
    })),
  }));
};

/**
 * @typedef {Object} VideoStrapiResponse
 * @property {string} id
 * @property {Object} attributes
 * @property {string} attributes.Title
 * @property {Object} attributes.hcn_article_category
 */

/**
 * @typedef {Object} VideoEntity
 * @property {string} id
 * @property {Object} category
 * @property {number} category.id
 * @property {string} title
 * @property {string} content
 * @property {string} image
 * @property {string} videothumbnail
 * @property {number} videothumbnailduration
 * @property {string} videoUrl
 */

/**
 * Fetches all videos by category ids
 * @param {number[]} categoryIds
 * @param {number} pageNumber
 * @param {number} pageSize
 * @returns {Promise<VideoEntity>}
 * @throws {Error}
 */
export const fetchVideosByCategoryIds = async (categoryIds, pageNumber, pageSize = 9) => {
  try {
    let queryFilterBuilder = "";
    categoryIds.forEach((cateId, index) => {
      if (index > 0) {
        queryFilterBuilder += "&";
      }
      queryFilterBuilder += `filters[$or][0][hcn_article_category][id][$eq]=${cateId}&filters[$or][1][hcn_article_category][Children][id][$eq]=${cateId}&filters[$or][2][hcn_article_category][Parent][id][$eq]=${cateId}`;
    });
    queryFilterBuilder += populateField("Videos", "v");
    // filters[$or][0][hcn_article_category][id][$eq]=${articleCategoryId}&filters[$or][1][hcn_article_category][Children][id][$eq]=${articleCategoryId}&filters[$or][2][hcn_article_category][Parent][id][$eq]=${articleCategoryId}
    const response = await apiClient.get(`api/videos?${queryFilterBuilder}&populate[carousel][fields][0]=url&populate[hcn_article_category][fields][0]=id&populate[hcn_article_category][fields][1]=title&populate[hcn_article_category][fields][2]=parent&populate[hcn_article_category][fields][3]=children&populate[hcn_article_category][populate][Parent][fields][0]=id&populate[hcn_article_category][populate][Parent][fields][1]=title&populate[hcn_article_category][populate][Children][fields][0]=id&populate[hcn_article_category][populate][Children][fields][1]=title&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`);
    return response.data.data?.map((e) => ({
      id: e.id,
      category: {
        id: e.attributes.hcn_article_category.data.id,
      },
      title: e.attributes.Title,
      content: "lorem ipsumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      videoUrl: e.attributes.Videos.data[0].attributes.url,
    })
    );
  } catch (error) {
    console.error('Error fetching videos:', error);
    throw error;
  }
};


/**
 * @typedef {Object} QuoteEntity
 * @property {string} id
 * @property {Object} category
 * @property {number} category.id
 * @property {string} title
 * @property {string} content
 * @property {string} cover_image
 */

/**
 * Fetches all quotes by category ids
 * @param {number[]} categoryIds
 * @param {number} pageNumber
 * @param {number} pageSize
 * @returns {Promise<QuoteEntity>}
 * @throws {Error}
 */
export const fetchQuotesByCategoryIds = async (categoryIds, pageNumber, pageSize = 9) => {
  try {
    let queryFilterBuilder = "";
    categoryIds.forEach((cateId, index) => {
      if (index > 0) {
        queryFilterBuilder += "&";
      }
      queryFilterBuilder += `filters[$or][0][hcn_article_category][id][$eq]=${cateId}&filters[$or][1][hcn_article_category][Children][id][$eq]=${cateId}&filters[$or][2][hcn_article_category][Parent][id][$eq]=${cateId}`;
    });
    // filters[$or][0][hcn_article_category][id][$eq]=${articleCategoryId}&filters[$or][1][hcn_article_category][Children][id][$eq]=${articleCategoryId}&filters[$or][2][hcn_article_category][Parent][id][$eq]=${articleCategoryId}
    const response = await apiClient.get(`api/quotes?${queryFilterBuilder}&populate[carousel][fields][0]=url&populate[hcn_article_category][fields][0]=id&populate[hcn_article_category][fields][1]=title&populate[hcn_article_category][fields][2]=parent&populate[hcn_article_category][fields][3]=children&populate[hcn_article_category][populate][Parent][fields][0]=id&populate[hcn_article_category][populate][Parent][fields][1]=title&populate[hcn_article_category][populate][Children][fields][0]=id&populate[hcn_article_category][populate][Children][fields][1]=title&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`);
    return response.data.data?.map((e) => ({
      id: e.id,
      category: {
        id: e.attributes.hcn_article_category.data.id,
      },
      title: e.attributes.Title,
      content: "lorem ipsumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      cover_image: "https://cdn2.thecatapi.com/images/0XYvRd7oD.jpg",
    })
    );
  } catch (error) {
    console.error('Error fetching videos:', error);
    throw error;
  }
};

export const fetchEbook = async (token) => {
  try {
    const response = await apiClient.get(`api/ebook`, {
      headers: {
        "x-shopify-access-token": token ?? ""
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};

/**
 * @typedef {Object} MealPlan
 * @property {number} id
 * @property {string} name
 * @property {string[]} recipes
 */

const transformMealPlanResponseData = (data) => ({
  id: data.id,
  name: data.name,
  recipes: data.mealPlanItems,
});

/**
 * Creates a meal plan
 * @returns {Promise<MealPlan>}
 */
export const createUpdateMealPlan = async (mealPlan, token) => {
  const mealPlanRequestData = {
    name: mealPlan.name,
    mealPlanItems: mealPlan.recipes,
  };
  const headers = {
    "x-shopify-access-token": token
  };

  try {
    const response = !!mealPlan.id ?
      await apiClient.put(`api/meal-plan/${mealPlan.id}`, mealPlanRequestData, {
        headers
      }) :
      await apiClient.post(`api/meal-plan`, mealPlanRequestData, {
        headers
      });

    return transformMealPlanResponseData(response.data);
  } catch (error) {
    console.error('Error creating meal plan:', error);
    throw error;
  }
}

export const removeMealPlan = async (mealPlanId, token) => {
  try {
    await apiClient.delete(`api/meal-plan/${mealPlanId}`, {
      headers: {
        "x-shopify-access-token": token
      }
    });
  } catch (error) {
    console.error('Error removing meal plan:', error);
    throw error;
  }
}

/**
 * Fetches all meal plans
 * @returns {Promise<MealPlan[]>}
 */
export const getMealPlans = async (token) => {
  try {
    const response = await apiClient.get(`api/meal-plan`, {
      headers: {
        "x-shopify-access-token": token
      }
    });
    return response.data.map(item => transformMealPlanResponseData(item));
  } catch (error) {
    console.error('Error getting meal plans:', error);
    throw error;
  }
}

/**
 * Adds a recipe to a meal plan
 * @returns {Promise<MealPlan>}
 */
export const addProductToMealPlan = async (mealPlan, productId, token) => {
  const newMealPlan = {
    name: mealPlan.name,
    mealPlanItems: [...mealPlan.recipes, productId]
  };

  try {
    const response = await apiClient.put(`api/meal-plan/${mealPlan.id}`, newMealPlan, {
      headers: {
        "x-shopify-access-token": token
      }
    });
    return transformMealPlanResponseData(response.data);
  } catch (error) {
    console.error('Error adding recipe to meal plan:', error);
    throw error;
  }
}

/**
 * @typedef {Object} NutritionItem
 * @property {string} name
 * @property {number?} amount
 * @property {string} unit
 * @property {number?} percentage
 */

/**
 * @typedef {Object} NutritionAnalysis
 * @property {Object} micronutrients
 * @property {number} micronutrients.vitamins
 * @property {number} micronutrients.macrominerals
 * @property {number} micronutrients.microminerals
 * @property {number} micronutrients.aminoAcids
 * @property {NutritionItem[]} caloricsBreakdown
 * @property {Object} nutrients details of micronutrients
 * @property {NutritionItem[]} nutrients.vitamins
 * @property {NutritionItem[]} nutrients.macrominerals
 * @property {NutritionItem[]} nutrients.microminerals
 * @property {NutritionItem[]} nutrients.aminoAcids
 */

/**
 * get nutrition analysis
 * @returns {Promise<NutritionAnalysis>}
 */
export const getNutritionAnalysis = async (productIds, token) => {
  try {
    const searchParams = new URLSearchParams();
    productIds.forEach((productId) => searchParams.append('productIds[]', productId));
    const response = await apiClient.get(`api/nutrition-analysis?` + searchParams.toString(), {
      headers: {
        "x-shopify-access-token": token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting meal plans:', error);
    throw error;
  }
}