import React, {useState, useEffect} from 'react';
import {styles} from './participateDetailPageStyle';
// Styled Component
import {Container, withStyles} from '@material-ui/core';
import SectionContent from '../Sections/SectionContent';
// Resources
import articleBg from '~/assets/images/page/participate/details/detail_article.jpg';
import videoBg from '~/assets/images/page/participate/details/detail_video.jpg';
import InitiativesBg from '~/assets/images/page/participate/details/detail_bottom.jpg';
import data from '../data';
import {withRouter} from 'react-router-dom';
const themeColor = '#ffffff';
const btnColor = '#fec503';

// Section content mock data

const questions = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  'Quisque tempus enim eu eros molestie, eget porta tellus fermentum. Pellentes in ultricies tortor, ac fringilla libero.',
];
const articleContent =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus scelerisque luctus nibh ornare ultricies. Sed consequat commodo urna, eu pellentesque enim luctus quis. Suspendisse arcu elit, ullamcorper non mauris et, hendrerit consectetur sem. Aenean ac pretium turpis. Pellentesque vitae justo luctus, sodales enim a, suscipit libero. Sed consequat euismod convallis.  Donec auctor augue id ipsum dictum, vitae euismod……..';

const videoDescription =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse platea dictumst.';
const videoName = 'VIDEO NAME';

const guideContent =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi. ';

const InititativesDes =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi. ';
function ParticipateDetail(props) {
  const {classes, location, history} = props;

  const [itemData, setItemData] = useState({});

  const {name: title = '', tag: subHeading = []} = itemData;

  useEffect(() => {
    
    const id = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1));
    const item = data.find((item) => item.id === id);

    if (item) {
      setItemData(item);
    } else {
      history.push('/participate');
    }
  }, [history, location.pathname]);

  return (
    <div className={classes.backgroundWrapper} style={{backgroundImage: `url(${itemData.image})`}}>
      <div className={classes.content}>
        <Container maxWidth="lg">
          <SectionContent
            suspendBtnText={itemData.titleTop}
            questions={questions}
            articleContent={articleContent}
            textColor={themeColor}
            themeColor={themeColor}
            btnColor={btnColor}
            articleBg={articleBg}
            videoBg={videoBg}
            videoDescription={videoDescription}
            videoName={videoName}
            guideContent={guideContent}
            initiativesBg={InitiativesBg}
            inititativesDes={InititativesDes}
          />
        </Container>
      </div>
    </div>
  );
}

export default withStyles(styles)(withRouter(ParticipateDetail));
