// @ts-check
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, MenuItem, IconButton, Typography } from '@material-ui/core/';
import FoodItemsCarousel from './FoodItemsCarousel';
import MinimizeIcon from '@material-ui/icons/Remove';
import Collapse from '@material-ui/core/Collapse';
import NutritionalSummary from '../../NutritionalDetail/Sections/NutritionalSummary';
import HealthCreatorIcon from '~/assets/images/page/lifeforcefood/healthCreatorIcon.svg';
import sushiImg from '~/assets/images/page/lifeforcefood/detail/sushi7.png';
import FormBuilder from 'shared/formBuilder';
import {
  createNewMealPlan as createNewMealPlanStructure,
  createdMealPlan,
} from 'shared/formStructures/mealPlan';
import MealPlan from '../../MealPlaner/MealPlan';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  container: {
    background: 'white',
    margin: 0,
    padding: `0 ${theme.spacing(2)}px`,
  },
  heading: {
    color: '#9aa300',
    fontSize: '1.2rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    padding: '2px 8px',
    [theme.breakpoints.down('500')]: {
      fontSize: '1rem',
    },
  },
  nutriAdeqMessage: {
    textAlign: 'center',
    color: 'red',
  },
  totalPrice: {
    fontSize: '0.9rem',
    color: '#9aa200',
    fontWeight: 'bold',
    paddingRight: '1em',
    textAlign: 'right',
  },
  textField: {
    maxWidth: '30ch',
    minWidth: '15ch',
    width: '25ch',
  },
  minimizeIcon: {
    textAlign: 'right',
    '&>span': {
      padding: 0,
      color: '#9aa300',
    },
  },
  mealPlanWrapper: {
    gap: theme.spacing(2),
  }
}));

const ModifiedTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#9aa200',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#9aa200',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#9aa200',
      },
      '&:hover fieldset': {
        borderColor: '#9aa200',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9aa200',
      },
    },
  },
})(TextField);
const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    fontFamily: 'Montserrat',
    backgroundColor: '#9aa200',
    fontSize: '13px',
    padding: '5 30px',
    '&:hover': {
      backgroundColor: '#686D03',
    },
  },
}))(Button);

const OutlinedButton = withStyles((theme) => ({
  root: {
    marginTop: 2,
    borderColor: '#9aa200',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    color: '#9aa200',
    borderWidth: 2,
    '&:hover': {
      borderColor: '#9aa200',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      borderColor: '#9aa200',
    },
    '&:focus': {
      boxShadow: '#9aa200',
    },
  },
}))(Button);

const Container = (props) => {
  const { images = [], children, minimize, setMinimize } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.container}
      spacing={1}
      justify="space-between"
      style={{ borderTop: '1px solid rgb(154, 162, 0)' }}
    >
      <Grid
        container
        onClick={() => {
          setMinimize(!minimize);
        }}
        item
        xs={12}
        style={{ width: 'auto' }}
        alignItems="center"
      >
        <Grid item>
          <img src={HealthCreatorIcon} style={{ height: '1rem' }} />
        </Grid>
        <Grid item>
          <Typography className={classes.heading}>Health Creator Meal Planner</Typography>
        </Grid>
        <Grid item>
          <Collapse in={!minimize}>
            <Grid container item spacing={1}>
              {images.map((e) => {
                return (
                  <Grid item>
                    <img src={sushiImg} style={{ height: '1.2rem' }} />
                  </Grid>
                );
              })}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
      {/* <Grid item>
        <IconButton
          onClick={() => {
            setMinimize(!minimize);
          }}
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <MinimizeIcon />
        </IconButton>
      </Grid> */}
      <Collapse style={{ width: '100%' }} in={minimize}>
        {children}
      </Collapse>
    </Grid>
  );
};
const CreateNewMP = (props) => {
  return (
    <Grid container item xs={12} justify="center">
      <Grid xs={12} item style={{ textAlign: 'center' }}>
        <Typography>Create a new Meal Plan</Typography>
      </Grid>
      <FormBuilder
        spacing={2}
        justify="center"
        structure={createNewMealPlanStructure}
        onSubmit={(values) => {
          return props.onClickCreateMealPlan(values);
        }}
      />
    </Grid>
  );
};

/**
 * @param {{
 * mealPlans: import("shared/react-query/strapiAPI").MealPlan[];
 * selectedMealPlan: number;
 * onClickCreateMealPlan: Function;
 * onClickSelectMealPlan: Function;
 * setMinimize: Function;
 * minimize: boolean;
 * checkoutOnClick: Function;
 * removeOnClick: Function;
 * }} props
 */
const MealPlanner = (props) => {
  const classes = useStyles();
  const {
    mealPlans,
    selectedMealPlan,
    onClickCreateMealPlan,
    onClickSelectMealPlan,
    setMinimize,
    minimize,
    checkoutOnClick,
    removeOnClick,
  } = props;
  const history = useHistory();
  const [nutritionAna, setNutritionAna] = useState(false);
  const totalPrice = (cartArr) => {
    return cartArr.reduce((acc, cv) => {
      return acc + cv.quantity * cv.price;
    }, 0);
  };
  const modCreatedMealPlan = (name, day) => {
    return createdMealPlan(() => {
      onClickSelectMealPlan(null);
    })
      .slice()
      .map((e) => {
        const a = { ...e, gridItemProps: JSON.parse(JSON.stringify(e.gridItemProps)) };
        if (a.inputFieldProps.type === 'submit') {
          return null;
        }
        a.gridItemProps = {
          ...a.gridItemProps,
          xs: 12,
          sm: true,
          md: true,
        };
        if (a.inputFieldProps.key == 'name') {
          a.initialValue = name;
        }
        if (a.inputFieldProps.key == 'day') {
          a.initialValue = day;
        }
        return a;
      })
      .filter((e) => e !== null);
  };

  const handleNavigateNutritionAnalysis = (mealPlanId) => {
    const searchParams = new URLSearchParams();
    searchParams.append('mealPlanId', mealPlanId);
    history.push("/shop-by-product-and-services/nutritional-details?"+searchParams.toString());
  };
  const render = () => {
    if (mealPlans.length == 0) {
      return (
        <Grid container item xs={12} justify="center" style={{
          height: '75vh',
        }} >
          <Grid item xs={12} sm={8} md={6}>
            <CreateNewMP onClickCreateMealPlan={onClickCreateMealPlan} />
          </Grid>
        </Grid>
      );
    }
    if (selectedMealPlan) {
      const obj = mealPlans.find((e) => e.id === selectedMealPlan);
      return (
        <Grid container item xs={12} spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <FoodItemsCarousel items={obj.recipes} removeOnClick={removeOnClick} mealPlanID={selectedMealPlan} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <OutlinedButton
              onClick={() => {
                setNutritionAna(!nutritionAna);
              }}
              variant="outlined"
            >
              Nutrition Analysis
            </OutlinedButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormBuilder
              spacing={2}
              xs={12}
              justify="flex-start"
              getValues={(values) => console.log(values)}
              structure={modCreatedMealPlan(obj.name, obj.day)}
              onSubmit={(values) => {
                return onClickSelectMealPlan(values.selectedMealPlan);
              }}
            />
          </Grid>
          <Grid container item className={classes.container} xs={12} sm={6} justify="flex-end" spacing={1}>
            <Grid item xs={12} className={classes.totalPrice}>
              Total: ${totalPrice(obj.recipes)}
            </Grid>
            <Grid item>
              <ColorButton
                onClick={() => {
                  checkoutOnClick(obj.cart).then((res) => {
                    window.open(res, '_blank');
                  });
                }}
              >
                Checkout
              </ColorButton>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    if (mealPlans.length > 0 && selectedMealPlan == null) {
      return (
        <Grid container direction='row' spacing={1} className={classes.container}
          style={{
            height: '75vh',
            overflow: 'auto',
            gap: '8px',
            marginBottom: '8px',
          }}>
          {mealPlans.map((mealPlan) => (<MealPlan key={mealPlan.id} mealPlan={mealPlan} onNavigateNutritionAnalysis={handleNavigateNutritionAnalysis} />))}
          <Grid item xs={12} sm={12}>
            <CreateNewMP onClickCreateMealPlan={onClickCreateMealPlan} />
          </Grid>
        </Grid>
      );
    }
  };
  return (
    <Container images={[]} minimize={minimize} setMinimize={setMinimize}>
      {render()}
    </Container>
  );
  // return (
  //   <Grid
  //     container
  //     spacing={1}
  //     style={{borderTop: '1px #9aa200 solid', maxHeight: '100vh'}}
  //     className={classes.container}
  //   >
  //     <Grid container spacing={1} item xs={12}>
  //       <Grid container item xs={11} className={classes.heading} alignItems="center">
  //         <img src={HealthCreatorIcon} style={{height: '1rem', padding: '0 8px'}} />
  //         Health Creator Meal Planner
  //         <Collapse in={!minimize}>
  //           <div style={{marginLeft: 4, display: 'flex', alignItems: 'center'}}>
  //             <img src={sushiImg} style={{height: '1.2rem', paddingLeft: 4}} />
  //             <img src={sushiImg} style={{height: '1.2rem', paddingLeft: 4}} />
  //             <img src={sushiImg} style={{height: '1.2rem', paddingLeft: 4}} />
  //             <img src={sushiImg} style={{height: '1.2rem', paddingLeft: 4}} />
  //             <img src={sushiImg} style={{height: '1.2rem', paddingLeft: 4}} />
  //             <img src={sushiImg} style={{height: '1.2rem', paddingLeft: 4}} />
  //             <img src={sushiImg} style={{height: '1.2rem', paddingLeft: 4}} />
  //             <img src={sushiImg} style={{height: '1.2rem', paddingLeft: 4}} />
  //           </div>
  //         </Collapse>
  //         <Grid item container></Grid>
  //       </Grid>
  //       <Grid item xs={1} justify="flex-end" className={classes.minimizeIcon}>
  //         <IconButton
  //           onClick={() => {
  //             setMinimize(!minimize);
  //           }}
  //           color="primary"
  //           aria-label="upload picture"
  //           component="span"
  //         >
  //           <MinimizeIcon />
  //         </IconButton>
  //       </Grid>
  //     </Grid>
  //     <Collapse style={{width: '100%'}} in={minimize}>
  //       <Grid style={{padding: '0 10px'}} item xs={12}>
  //         <FoodItemsCarousel items={[]} />
  //       </Grid>
  //       <Grid container className={classes.container} spacing={1} item justify="center" xs={12}>
  //         <Grid className={classes.nutriAdeqMessage} item xs={12}>
  //           Add more food. Your meal plan lacks nutritional adequacy.
  //         </Grid>
  //         <Grid item>
  //           <OutlinedButton
  //             onClick={() => {
  //               setNutritionAna(!nutritionAna);
  //             }}
  //             variant="outlined"
  //           >
  //             Nutrition Analysis
  //           </OutlinedButton>
  //         </Grid>
  //         <Collapse style={{width: '100%'}} in={nutritionAna}>
  //           <Grid container item xs={12} style={{height: '100%', maxHeight: 300, overflow: 'auto', color: 'black'}}>
  //             <NutritionalSummary />
  //           </Grid>
  //         </Collapse>
  //       </Grid>
  //       <Grid container className={classes.container} spacing={1} item xs={12}>
  //         <Grid
  //           container
  //           item
  //           xs={6}
  //           // justify="space-around"
  //           alignItems="flex-end"
  //           className={classes.container}
  //           spacing={1}
  //         >
  //           <Grid item>
  //             <ModifiedTextField
  //               id="select"
  //               label="Meal Plan for"
  //               value="1"
  //               select
  //               className={classes.textField}
  //               variant="outlined"
  //               size="small"
  //             >
  //               <MenuItem value="1">Monday</MenuItem>
  //               <MenuItem value="2">Tuesday</MenuItem>
  //             </ModifiedTextField>
  //           </Grid>
  //           <Grid item>
  //             <ModifiedTextField className={classes.textField} variant="outlined" size="small" label="Meal plan name" />
  //           </Grid>
  //         </Grid>
  //         <Grid container className={classes.container} item xs={6} justify="flex-end" spacing={1}>
  //           <Grid item xs={12} className={classes.totalPrice}>
  //             Total: $90.00
  //           </Grid>
  //           <Grid item>
  //             <ColorButton>Add to Cart</ColorButton>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Collapse>
  //   </Grid>
  // );
};

export default MealPlanner;
